export const framed = true
export const partner = 'betsperts'
export const overridePerms = true
export const authed = false
export const customPresets = false

// CSS Overrides
export const overridePrimary = '#2ce584'
export const overrideSecondary = '#999'
export const overrideCheckboxColor = '#0079f0'
export const overrideButtonText = '#fff'
export const overrideBackground = null
export const overrideColor = "#000"
export const overridePlayerText = "#000"
export const overrideBackgroundSecondary = "fff"
// export const overrideTableHighlight = "#41464f"
export const overrideTableHighlight = "rgb(230, 247, 255)"
// TEMP remove
export const api_token = ''
export const api_id = ''

export const allowCheatsheets = true