const VERSION = 1

// Get which NFL week we are in
export const week = (() => {
  const _date = new Date()

  const nflStartDate = new Date('2024-09-03T12:00:00')
  if (_date < nflStartDate) return 1
  const m = Math.round((_date-nflStartDate)/(1000*60*60*24)) / 7

  let _week = Math.floor(m) + 1
  // Set to super bowl week if we progressed past that week
  if (_week > 22) {
    _week = 22
  }
  return _week

  // return 1
})()
// export const week = 13

export const season = 2024

export const weekOptions = Array.from({length: week}, (_, i) => i + 1)
export const allWeeks = Array.from({length: 22}, (_, i) => i + 1)
// export const weekOptions = [1]

export const seasonOptions = [2023]


